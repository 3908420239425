import { createSlice } from '@reduxjs/toolkit';

const senseSettingsSlice = createSlice({
  name: 'senseSettings',
  initialState: {
    activeLevel1: false,
    activeLevel2: false,
    activeLevel3: false,
    enablePort: false,
    level: null,
    level1: null,
    level2: null,
    level3: null,
    levelAlarmDelay1: false,
    levelAlarmDelay2: false,
    levelAlarmDelay3: false,
    levelDelayTime1: 0,
    levelDelayTime2: 0,
    levelDelayTime3: 0,
    polarityLevel1: 0,
    polarityLevel2: 0,
    polarityLevel3: 0,
    portRate: null,
    isSenseAlarm: false,
    isSenseOfa: false,
    isSenseMinMax: false,
    modbus: 0,
    ofaMinutes1: null,
    ofaMinutes2: null,
    ofaMinutes3: null,
    enableOfa1: false,
    enableOfa2: false,
    enableOfa3: false,
    max1: null,
    max2: null,
    max3: null,
    maxLimit1: null,
    maxLimit2: null,
    maxLimit3: null,
    min1: null,
    min2: null,
    min3: null,
    minLimit1: null,
    minLimit2: null,
    minLimit3: null,
  },
  reducers: {
    setActiveLevel1(state) {
      state.activeLevel1 = !state.activeLevel1;
    },
    setActiveLevel2(state) {
      state.activeLevel2 = !state.activeLevel2;
    },
    setActiveLevel3(state) {
      state.activeLevel3 = !state.activeLevel3;
    },
    setEnablePort(state) {
      state.enablePort = !state.enablePort;
    },
    setIsSenseAlarm(state) {
      state.isSenseAlarm = !state.isSenseAlarm;
    },
    setIsSenseOfa(state) {
      state.isSenseOfa = !state.isSenseOfa;
    },
    setIsSenseMinMax(state) {
      state.isSenseMinMax = !state.isSenseMinMax
    },
    setLevel(state, action) {
      state.level = action.payload;
    },
    setLevel1(state, action) {
      state.level1 = action.payload;
    },
    setLevel2(state, action) {
      state.level2 = action.payload;
    },
    setLevel3(state, action) {
      state.level3 = action.payload;
    },
    setLevelAlarmDelay1(state) {
      state.levelAlarmDelay1 = !state.levelAlarmDelay1;
    },
    setLevelAlarmDelay2(state) {
      state.levelAlarmDelay2 = !state.levelAlarmDelay2;
    },
    setLevelAlarmDelay3(state) {
      state.levelAlarmDelay3 = !state.levelAlarmDelay3;
    },
    setLevelDelayTime1(state, action) {
      state.levelDelayTime1 = action.payload;
    },
    setLevelDelayTime2(state, action) {
      state.levelDelayTime2 = action.payload;
    },
    setLevelDelayTime3(state, action) {
      state.levelDelayTime3 = action.payload;
    },
    setMax1(state, action) {
      state.max1 = action.payload;
    },
    setMax2(state, action) {
      state.max2 = action.payload;
    },
    setMax3(state, action) {
      state.max3 = action.payload;
    },
    setMin1(state, action) {
      state.min1 = action.payload;
    },
    setMin2(state, action) {
      state.min2 = action.payload;
    },
    setMin3(state, action) {
      state.min3 = action.payload;
    },
    setMinLimit1(state, action) {
      state.minLimit1 = parseInt(action.payload)
    },
    setMinLimit2(state, action) {
      state.minLimit2 = parseInt(action.payload)
    },
    setMinLimit3(state, action) {
      state.minLimit3 = parseInt(action.payload)
    },
    setMaxLimit1(state, action) {
      state.maxLimit1 = parseInt(action.payload)
    },
    setMaxLimit2(state, action) {
      state.maxLimit2 = parseInt(action.payload)
    },
    setMaxLimit3(state, action) {
      state.maxLimit3 = parseInt(action.payload)
    },
    setEnabledOfa1(state, action) {
      state.enableOfa1 = action.payload;
    },
    setEnabledOfa2(state, action) {
      state.enableOfa2 = action.payload;
    },
    setEnabledOfa3(state, action) {
      state.enableOfa3 = action.payload;
    },
    setModbus(state, action) {
      state.modbus = action.payload;
    },
    setOfaMinutes1(state, action) {
      state.ofaMinutes1 = action.payload;
    },
    setOfaMinutes2(state, action) {
      state.ofaMinutes2 = action.payload;
    },
    setOfaMinutes3(state, action) {
      state.ofaMinutes3 = action.payload;
    },
    setPolarityLevel1(state, action) {
      state.polarityLevel1 = action.payload;
    },
    setPolarityLevel2(state, action) {
      state.polarityLevel2 = action.payload;
    },
    setPolarityLevel3(state, action) {
      state.polarityLevel3 = action.payload;
    },
    setPortRate(state, action) {
      state.portRate = action.payload;
    },
  },
});

export const senseSettingsActions = senseSettingsSlice.actions;

export default senseSettingsSlice;
