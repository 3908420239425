export const iw_motor_pump_alarm_map = {
    1: { value: 'Chemical is finished', levelMessage: 'problem', level: 3 },
    2: { value: 'Lack of flow', levelMessage: 'problem', level: 3 },
    3: { value: 'OFA - Over feed alarm', levelMessage: 'problem', level: 3 },
    4: { value: 'PTC intervention (temperature probe)', levelMessage: 'warning', level: 2 },
    5: { value: 'Inhibit waiting', levelMessage: 'running', level: 1 },
    6: { value: 'Lack of measure communication', levelMessage: 'warning', level: 2 },
    7: { value: 'Pump in calibration', levelMessage: 'running', level: 1 },
    8: { value: 'Startup delay', levelMessage: 'running', level: 1 },
    9: { value: 'Delay after calibration', levelMessage: 'running', level: 1 },
    10: { value: 'Overflow 1xN', levelMessage: 'warning', level: 2 },
    11: { value: 'Measure at minimum', levelMessage: 'warning', level: 2 },
    12: { value: 'Measure at maximum', levelMessage: 'warning', level: 2 },
    13: { value: 'Overflow ppm', levelMessage: 'warning', level: 2 },
    14: { value: 'Out of programmed time slot', levelMessage: 'running', level: 1 },
    15: { value: 'Low level', levelMessage: 'warning', level: 2 },
}