import axios from 'axios';
import { logout } from 'store/login-actions';
// Config for the app
import envConfig from '../env-config.json';

/**
 * Create axios instance
 * Base url should be saved in .env
 */
const instance = axios.create({
    baseURL: `${envConfig.REACT_APP_BASE_URL}/${'v1'}`,
});

// Setting bearer from cookie for login

instance.interceptors.response.use(
    (response) => {
        const formattedResponse = {
            ...response.data,
        };
        return formattedResponse;
    },
    (error) => {
        const { data = {}, status = {} } = error.response;
        const { errors = [] } = data;
        if (status === 401 && (errors.length === 1 && errors[0] === 'Unauthorized' || errors.length === 0)) {
            localStorage.setItem('error', JSON.stringify('unauthorized'));
            logout();
        }
        return {
            statusCode: status,
            msg: errors.join('\n'),
        };
    },
);

export default instance;

