import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import store from './store/index';
// Config for the app
import envConfig from './env-config.json';

if (envConfig.REACT_APP_APP_NAME === 'insideWater') {
  import('styles/insideWater/_variables.scss');
} else {
  import('styles/ultraConnect/_variables.scss');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

window.addEventListener('error', function (event) {
  console.error('Error:', event.message);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
