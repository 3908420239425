import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Config for the app
import envConfig from './env-config.json';

// Determina la cartella delle traduzioni in base alla variabile d'ambiente
const appName = envConfig.REACT_APP_APP_NAME || 'insideWater'; // 'insideWater' di default
const translationsPath = `/locales/${appName}/`;

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: translationsPath + '{{lng}}/{{ns}}.json', // Carica i file di traduzione per la lingua specifica
    },
    fallbackLng: 'en', // Lingua di default
    debug: false,
    interpolation: {
      escapeValue: false, // React gestisce già la protezione dalle iniezioni di XSS
    },
  });

export default i18next;