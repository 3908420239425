// Mapping di measure.value ai rispettivi modemPrefix
export const measureModemMap = {
    'pH': '2',
    'orp': '3',
    'ppmMv': '4',
    'ppmMa': '5',
    'ptcu': '6',
    default: '7',
};

// Mapping di range.value ai rispettivi modemSuffix
export const rangeModemMap = {
    2: '00',
    'k2': '00',
    5: '01',
    'k5': '01',
    10: '02',
    'k10': '02',
    20: '03',
    200: '04',
    2000: '05',
    99: '99',
    default: '00',
};
export const channel_options = [
    { label: 'pH', value: 'pH' },
    { label: 'Orp', value: 'orp', unit: 'mV' },
    { label: 'ppm - mV', value: 'ppmMv', unit: 'ppm' },
    { label: 'ppm - 4-20 ma', value: 'ppmMa', unit: 'ppm' },
    { label: 'Pt-Cu', value: 'ptcu', unit: 'ppm' },
    { label: 'CD', value: 'cd' },
];
export const channel_options_map = {
    'PR': [
        { label: 'pH', value: 'pH' },
        { label: 'Orp', value: 'orp' },
    ],
    'CD': [
        { label: 'CD', value: 'cd' },
    ],
    'CL': [
        { label: 'ppm - mV', value: 'ppmMv' },
        { label: 'ppm - 4-20 ma', value: 'ppmMa' },
        { label: 'Pt-Cu', value: 'ptcu' }
    ]
}
export const range_options = [
    { label: '0-2 ppm', value: 2, type: '00' },
    { label: '0-5 ppm', value: 5, type: '01' },
    { label: '0-10 ppm', value: 10, type: '02' },
    { label: '0-20 ppm', value: 20, type: '03' },
    { label: '0-200 ppm', value: 200, type: '04' },
    { label: '0-2000 ppm', value: 2000, type: '05' },
    { label: 'sonda custom', value: 99, type: '99' }
]
export const cd_options = [
    { label: 'K=1 (for values up to 20000µS)', value: 'k2' },
    { label: 'K=5 (for values up to 20000µS)', value: 'k5' },
    { label: 'K=10 (for values up to 20000µS)', value: 'k10' },
]